import React, { useEffect, useRef, useState } from "react";
import { useAppState } from "../../context/AppStateContext";
import { ActionTypes } from "../../reducer/actionTypes";

import { useNavigate, useLocation } from "react-router-dom";

import "./ChatBody.css";
import { fetchConversation, getSessionID } from "../../api/_request";
import { useAuth } from "../../auth";
import DelayedRender from "../delayedRender/DelayedRender";
import ContactCard from "../cards/contactCard/ContactCard";
import MediaCard from "../cards/mediaCard/MediaCard";
import {
  avatarImageClass,
  buttonItemClass,
  myResponseClass,
  responseAreaClass,
  responseButtonsClass,
  serverResponseClass,
} from "../../helpers/helperClasses";
import AudioResponse from "../responses/AudioResponse";
import ContactCardAlt from "../cards/contactCard/ContactCardAlt";
import Cookies from "js-cookie";

import { RefreshCw } from "lucide-react";

import { v4 as uuidv4 } from "uuid";
import { addMessage, getMessages } from "../../indexedDB/dbHelper";
import { useLanguage } from "../../context/LanguageContext";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@radix-ui/react-tooltip";
import {
  convertBase64ToBlob,
  parseHtmlString,
  sendMessageToApiCore,
  websiteRegex,
} from "../../lib/utils";
import HtmlLinkCard from "../cards/htmlLinkCard/HtmlLinkCard";
import HtmlRenderCard from "../cards/htmlRenderer/HtmlRenderCard";
import Drawer from "../drawer/Drawer";

import { signInAnonymously } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { auth, storage } from "../../config/firebaseConfig";

import SignaturePad from "react-signature-canvas";
import dayjs from "dayjs";
import { Image } from "lucide-react";
import AudioChatResponse from "./chatResponses/AudioChatResponse";
import NotLoggedInChatBody from "./NotLoggedInChatBody";

const ChatBody: React.FC = () => {
  const { state, dispatch } = useAppState();
  const { translate } = useLanguage();
  const { currentUser } = useAuth();
  const uniqueID = crypto.randomUUID().toString();
  const navigate = useNavigate();
  const cachedLanguages = sessionStorage.getItem("languagesList");
  const isInitialRender = useRef(true);

  const chatHistoryRef = useRef<HTMLDivElement>(null);

  const search = useLocation().search;
  const searchBarParams = new URLSearchParams(search);

  const token_url = searchBarParams.get("token");
  const cookie_token = Cookies.get("authToken");

  //Search Params
  const mood = searchBarParams.get("mood");

  const messageValue = searchBarParams.get("message");
  const payloadValue = searchBarParams.get("payload");
  const buttonValue = searchBarParams.get("button_id");
  const notificationID = searchBarParams.get("notification_id");

  //const stellaSessionID = sessionStorage.getItem("stella_s_id");

  //States
  const [showAnimation, setShowAnimation] = useState<boolean>(true);
  const [regenerate, setRegenerate] = useState<boolean>(false);
  const [previousMessages, setPreviousMessages] = useState<any>([]);
  const [isDelayedRenderingComplete, setIsDelayedRenderingComplete] =
    useState<boolean>(false);
  const [recallSessionID, setRecallSessionID] = useState<boolean>(false);
  const [resDelay, setResDelay] = useState<number>(1000);

  const [signatureDrawer, setSignatureDrawer] = useState<boolean>(true);
  const [signatureURL, setSignatureURL] = useState<null | string>(null);

  const signatureCanvasRef = useRef<SignaturePad | null>(null);

  //Use Effects for initialMessage
  useEffect(() => {
    let payload = createPayload();

    if (
      Object.keys(payload).length &&
      state.isUserLoggedIn &&
      state?.chatHistory?.length == 0 &&
      state.sessionID !== ""
    ) {
      //loadMessages();
      fetchInitialConversation(state.userID, payload);
    }
  }, [messageValue, buttonValue, state.sessionID, state.isUserLoggedIn]);

  //console.log(" state.sessionID", state);

  // Scroll chat box to the bottom
  useEffect(() => {
    scrollChatToBottom();
  }, [state.chatHistory]);

  const createPayload = () => {
    let payload = {};

    if (state.isUserLoggedIn && state.sessionID !== "") {
      if (mood !== undefined && mood !== null) {
        payload = {
          jsonrpc: "2.0",
          params: {
            sender_id: state.sessionID,
            mood,
          },
        };
      } else if (messageValue !== undefined && messageValue !== null) {
        payload = {
          jsonrpc: "2.0",
          params: {
            sender_id: state.sessionID,
            message: messageValue,
          },
        };
      } else if (buttonValue !== undefined && buttonValue !== null) {
        payload = {
          jsonrpc: "2.0",
          params: {
            sender_id: state.sessionID,
            button_id: buttonValue,
          },
        };
      } else if (notificationID !== undefined && notificationID !== null) {
        payload = {
          jsonrpc: "2.0",
          params: {
            sender_id: state.sessionID,
            notification_id: notificationID,
          },
        };
      } else if (payloadValue !== undefined && payloadValue !== null) {
        payload = {
          jsonrpc: "2.0",
          params: {
            sender_id: state.sessionID,
            payload: payloadValue,
          },
        };
      } else {
        payload = {
          jsonrpc: "2.0",
          params: {
            sender_id: state.sessionID,
            message: "Hi!",
          },
        };
      }
    }

    return payload;
  };

  const uploadSignatureToFirebase = async (base64Image: string) => {
    try {
      //const dirName = dayjs().format("MMMM_YYYY");
      const blob = convertBase64ToBlob(base64Image);
      const storageRef = ref(
        storage,
        `/clinic-data-storage/clients/${currentUser?.Country}/${dayjs().format(
          "YYYY-MMM-DD_HH:mm:ss"
        )}_${currentUser?.Name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, blob);
      // console.log("fileType", fileType);

      // setFileUploaded("uploading");
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          //console.log("Uploading...", percent);
        },
        (error) => {
          console.error("Upload error:", error);
        },
        async () => {
          try {
            const url = await getDownloadURL(uploadTask.snapshot.ref);

            setSignatureURL(url);

            const newMessage = {
              _id: uniqueID,
              response: "self",
              type: "signature",
              message: url,
              avatar: currentUser?.ProfileImage,
              uploadedItemURL: url,
              uploadedItemImage: url,
            };

            dispatch({
              type: ActionTypes.UPDATE_CHAT_HISTORY,
              payload: [...state.chatHistory, newMessage, "loading"],
            });

            onCloseSignature();
            signatureCanvasRef?.current?.clear();
          } catch (error) {
            console.error("Error getting download URL:", error);
            // Handle error if needed
          }
        }
      );
    } catch (error) {
      console.error("Error while uploading");
    } finally {
    }
  };

  async function loadMessages() {
    const loadedMessages = await getMessages();

    // dispatch({
    //   type: ActionTypes.UPDATE_CHAT_HISTORY,
    //   payload: loadedMessages,
    // });
    setPreviousMessages(loadedMessages);
  }

  /*Log saved messages inside indexedDB*/
  //console.log("previousMessages", previousMessages);

  const fetchInitialConversation = async (userID: string, body: any) => {
    const initialConversation = await fetchConversation(
      userID,
      JSON.stringify(body)
    );

    // console.log("initialConversation", initialConversation.responseTime);

    if (initialConversation) {
      let delay =
        +initialConversation.responseTime > 1000
          ? 0
          : 1000 - +initialConversation.responseTime;

      setResDelay(delay);

      const messageBody = {
        _id: uniqueID,
        response: "server",
        data: initialConversation.responseData,
        avatar: state.selectedFace,
      };
      dispatch({
        type: ActionTypes.UPDATE_CHAT_HISTORY,
        payload: [messageBody],
      });
    }

    //addMessage(messageBody);
  };

  const scrollChatToBottom = () => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  };

  const handleRenderComplete = (isCompleted: boolean) => {
    if (isCompleted) {
      // Perform actions or handle completion status
      scrollChatToBottom();
      setShowAnimation(false);
      // setIsDelayedRenderingComplete(true)
    } else {
      scrollChatToBottom();
      setShowAnimation(true);
      //setIsDelayedRenderingComplete(false)
    }
    //console.log("isCompleted", isCompleted);
  };

  const navigateToURL = (url: string) => {
    window.location.href = url;
  };

  const handleOption = (emotion: {
    button_id?: React.Key | null | undefined;
    button_text: any;
  }) => {
    const newMessage = {
      response: "self",
      type: "button_id",
      buttonID: emotion.button_id,
      message: emotion.button_text,
      avatar: currentUser?.ProfileImage,
    };
    dispatch({
      type: ActionTypes.UPDATE_CHAT_HISTORY,
      payload: [...state.chatHistory, newMessage, "loading"],
    });
    // sendMessageToApi("button_id", emotion.button_id);
    //sendMessageToApi("button_id", 4060);
  };

  const handleRedirectFromURL = (url: string) => {
    if (window.ReactNativeWebView) {
      // window.ReactNativeWebView?.postMessage(
      //   JSON.stringify({
      //     type: "REDIRECT_TO_EXPERT",
      //     url,
      //   })
      // );
    } else {
      window.open(encodeURI(url), "_blank");
      //console.log("REDIRECT URL", url);
    }
  };

  const handleExtraUpdatesComponent2 = (res: any, delay: any) => {
    setRegenerate(false);
    //console.log("Additional handling for Component 2 after API response", res, delay);
    // Additional logic specific to Component 2, e.g., updating UI or state
  };

  const handleSmartButton = async (type: string, value: string) => {
    if (type === "SMART ACTION") {
      const selfRequestArray = state?.chatHistory?.filter(
        (item: any) => item.response === "self"
      );

      const lastRequest = selfRequestArray[selfRequestArray.length - 1];

      if (lastRequest !== undefined && lastRequest !== null) {
        dispatch({
          type: ActionTypes.UPDATE_CHAT_HISTORY,
          payload: [...state.chatHistory.slice(0, -2), lastRequest],
        });
      } else {
        dispatch({
          type: ActionTypes.UPDATE_CHAT_HISTORY,
          payload: [],
        });
      }
      setRegenerate(true);
      await sendMessageToApiCore(
        "smart_action",
        value,
        state,
        dispatch,
        handleExtraUpdatesComponent2
      );
    }
  };

  const getComputedStyleVariable = (variable: string) => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(variable)
      .trim();
  };

  const primaryColorValue = getComputedStyleVariable("--primary");
  const rgbaColor = primaryColorValue
    ? `rgba(${parseInt(primaryColorValue.slice(1, 3), 16)}, ${parseInt(
        primaryColorValue.slice(3, 5),
        16
      )}, ${parseInt(primaryColorValue.slice(5, 7), 16)}, 0.20)` // Adjust the alpha value as needed
    : "";

  //console.log("MATCH", websiteRegex.test(window.location.href))

  const onCloseSignature = () => {
    setSignatureDrawer(false);
  };

  const clearSignature = () => {
    if (signatureCanvasRef) {
      signatureCanvasRef?.current?.clear();
    }

    //console.log("Signature Image:", signatureCanvasRef);
  };

  const saveSignature = () => {
    if (signatureCanvasRef.current) {
      const signatureImage =
        signatureCanvasRef?.current?.toDataURL("image/png");
      uploadSignatureToFirebase(signatureImage);
      // console.log("Signature Image:", signatureImage);
    }
  };

  return (
    <div
      className="flex-1 overflow-y-auto px-3 py-[105px] md:pt-[80px] md:pb-5 space-y-4 my-3 Chat--Body h-screen sm:h-full sm:max-h-[calc(100%-87px)]"
      ref={chatHistoryRef}
    >
      {/*<Drawer isOpen={signatureDrawer} onClose={onCloseSignature}>
        <h3 className="text-xl font-semibold leading-6 text-gray-900 dark:text-white mb-10">
          Add Your Signature
        </h3>
        <SignaturePad
          ref={signatureCanvasRef}
          minDistance={0}
          //penColor="var(--primary)"
          canvasProps={{
            className: "h-[300px] border border-slate-400/80 rounded-xl ",
          }}
        />


        <div className="py-3 grid grid-cols-2 gap-2 max-w-sm w-full md:ml-auto">
          <button
            style={{ color: "var(--primary)" }}
            className="py-2.5 px-3 rounded-full md:rounded-lg border border-slate-400  text-sm font-medium"
            onClick={clearSignature}
          >
            Clear
          </button>

          <button
            onClick={saveSignature}
            style={{ backgroundColor: "var(--primary)" }}
            className="py-2.5 px-3 rounded-full md:rounded-lg text-white text-sm font-medium"
          >
            Save
          </button>
        </div>
      </Drawer>*/}
      {!state.isUserLoggedIn ? (
        !token_url && !cookie_token ? (
          <NotLoggedInChatBody />
        ) : (
          <div
            id="wave-animation"
            className={`${state.chatHistory.length > 0 && "ml-12"}`}
          >
            <div id="loading-bubble" className="bg-muted dark:bg-muted-dark">
              <div className="spinner">
                <div className="bounce1 bg-slate-400/50 dark:bg-slate-200"></div>
                <div className="bounce2 bg-slate-400/50 dark:bg-slate-200"></div>
                <div className="bounce3 bg-slate-400/50 dark:bg-slate-200"></div>
              </div>
            </div>
          </div>
        )
      ) : (
        <>
          {state?.chatHistory?.map((chat, i) => {
            const isLatest = i === state.chatHistory.length - 1;

            if (chat.response === "server") {
              return (
                <div className="space-y-3">
                  <div key={chat._id} className={responseAreaClass}>
                    {chat?.data !== undefined && (
                      <div className="avatar relative">
                        <img
                          src={chat?.avatar || state?.selectedFace}
                          alt="My Avatar"
                          className={`${avatarImageClass} ml-auto`}
                        />
                      </div>
                    )}

                    <div className="space-y-2 response--container w-[85%]">
                      {chat?.data?.map((item: any, i: number) => (
                        <div key={i}>
                          <DelayedRender
                            delay={i * 1000}
                            onComplete={handleRenderComplete}
                          >
                            {(item.text ||
                              item.audio ||
                              item.buttons ||
                              item.html ||
                              item.content ||
                              item.user) && (
                              <div
                                className={`response items-center ${
                                  state?.chatDirection
                                } ${serverResponseClass} ${
                                  item?.user || item?.content
                                    ? "Card--Response"
                                    : ""
                                }`}
                              >
                                {/* min-h-[35px] flex items-center in span tag when smart button is implemented */}
                                {item.text && (
                                  <div className="flex items-center gap-2">
                                    <div
                                      className="flex-1"
                                      dangerouslySetInnerHTML={{
                                        __html: `<div>${item.text
                                          .split("\n")
                                          .map((line: any) => {
                                            const sanitizedLine = line.replace(
                                              /[+*]/g,
                                              ""
                                            );
                                            if (line.startsWith("**")) {
                                              return `<p style="font-weight:bold;">${sanitizedLine}</p>`;
                                            } else if (line.startsWith("*")) {
                                              return `<li>${sanitizedLine.slice(
                                                0
                                              )}</li>`;
                                            } else if (/^\d+\./.test(line)) {
                                              return `<p>${sanitizedLine}</p>`;
                                            } else {
                                              return sanitizedLine;
                                            }
                                          })
                                          .join("<br/>")}</div>`,
                                      }}
                                    />

                                    {/* {item.audio && (
                                      <div className="flex items-center gap-3 w-max">
                                        <AudioResponse
                                          audioUrl={item.audio}
                                          responseType="server"
                                        />
                                      </div>
                                    )} */}
                                  </div>
                                )}

                                {item.buttons && (
                                  <div className={responseButtonsClass}>
                                    {item.buttons.map(
                                      (btn: {
                                        button_id: React.Key | null | undefined;
                                        button_text:
                                          | string
                                          | number
                                          | boolean
                                          | React.ReactElement<
                                              any,
                                              | string
                                              | React.JSXElementConstructor<any>
                                            >
                                          | Iterable<React.ReactNode>
                                          | React.ReactPortal
                                          | null
                                          | undefined;
                                      }) => (
                                        <div
                                          key={btn.button_id}
                                          className={`${buttonItemClass} transition-colors ease-in-out duration-200`}
                                          onClick={() => handleOption(btn)}
                                        >
                                          {btn.button_text}
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}

                                {
                                  item?.html && (
                                    <HtmlRenderCard htmlContent={item.html} />
                                  )
                                  // (!item?.html.includes("https://") ? (
                                  //   <div
                                  //     className="text-sm font-medium"
                                  //     dangerouslySetInnerHTML={{
                                  //       __html: item.html,
                                  //     }}
                                  //   ></div>
                                  // ) : (
                                  //   <HtmlLinkCard
                                  //     text={parseHtmlString(item.html).text}
                                  //     url={parseHtmlString(item.html).url}
                                  //   />
                                  // ))
                                }

                                {item?.content && (
                                  <MediaCard
                                    categoryID={item.content.category_id}
                                    id={item.content.content_id}
                                    title={item.content.title}
                                    type={item.content.type}
                                    description={item.content.description}
                                    thumbnail={item.content.thumb_image}
                                    largeImage={item.content.large_image}
                                    videoURL={item.content.video_url}
                                    audioURL={item.content.audio_url}
                                    video={item.content.video}
                                  />
                                )}

                                {item?.user && (
                                  <ContactCard
                                    key={item.user.id}
                                    id={item.user.id}
                                    fee={item.user.fee}
                                    description={item.user.description}
                                    name={`${item.user.name}`}
                                    image={`${item.user.image}`}
                                    profession={`${item.user.category}`}
                                    expInYears={`${item.user.experience}`}
                                    languages={`${item?.user.language}`}
                                    url={item.user.expert_detail_page}
                                    banner={item?.user.Expert_Banner}
                                  />
                                )}
                              </div>
                            )}
                          </DelayedRender>

                          {item.web_url && handleRedirectFromURL(item.web_url)}
                          {/* SMART BUTTON REFRESH */}
                          {isLatest && item?.smart_action && (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <button
                                    style={{
                                      backgroundColor: rgbaColor,
                                      color: `${
                                        state?.appTheme === "Light"
                                          ? "var(--primary)"
                                          : "#FFF"
                                      }`,
                                    }}
                                    className="w-max px-5 xs:max-w-[350px] md:max-w-[500px] h-10  flex items-center justify-center text-primary bg-primary/10 dark:bg-primary/30 rounded-xl dark:text-white gap-3 mx-auto my-4"
                                    onClick={() =>
                                      handleSmartButton(
                                        "SMART ACTION",
                                        item?.smart_action
                                      )
                                    }
                                  >
                                    <RefreshCw className="w-4 h-4" />
                                    <span className="font-medium text-sm">
                                      Refresh Suggestion
                                    </span>
                                  </button>
                                </TooltipTrigger>
                                <TooltipContent className="bg-black px-2 py-1 rounded-md">
                                  <p className="text-white text-xs">Refresh</p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            } else if (chat === "loading") {
              return (
                <div className="flex max-w-6xl mx-auto">
                  <div id="wave-animation" key={chat.id} className="">
                    <div
                      id="loading-bubble"
                      className="bg-muted dark:bg-muted-dark"
                    >
                      <div className="spinner">
                        <div className="bounce1 bg-slate-400/50 dark:bg-slate-200"></div>
                        <div className="bounce2 bg-slate-400/50 dark:bg-slate-200"></div>
                        <div className="bounce3 bg-slate-400/50 dark:bg-slate-200"></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (chat.response === "update") {
              /* Feature to be implemented that shows change in Language and Avatar */
              return (
                <div
                  key={i}
                  className="text-xs sm:text-sm py-2 px-4 w-max flex mx-auto bg-yellow-100/80 dark:bg-green-500/10 text-amber-700 dark:text-green-400 rounded-xl ring-1 ring-inset ring-amber-600/10 dark:ring-green-500/20 max-w-xs sm:max-w-sm"
                >
                  {chat.message}
                </div>
              );
            } else {
              return <AudioChatResponse chat={chat} />;
            }
          })}

          {(showAnimation || regenerate) && (
            <div className="flex max-w-6xl mx-auto">
              <div
                id="wave-animation"
                className={`${state.chatHistory.length > 0 && "ml-12"}`}
              >
                <div
                  id="loading-bubble"
                  className="bg-muted dark:bg-muted-dark"
                >
                  <div className="spinner">
                    <div className="bounce1 bg-slate-400/50 dark:bg-slate-200"></div>
                    <div className="bounce2 bg-slate-400/50 dark:bg-slate-200"></div>
                    <div className="bounce3 bg-slate-400/50 dark:bg-slate-200"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ChatBody;
